exports.components = {
  "component---src-components-pages-chain-page-tsx": () => import("./../../../src/components/pages/ChainPage.tsx" /* webpackChunkName: "component---src-components-pages-chain-page-tsx" */),
  "component---src-components-pages-city-list-page-tsx": () => import("./../../../src/components/pages/CityListPage.tsx" /* webpackChunkName: "component---src-components-pages-city-list-page-tsx" */),
  "component---src-components-pages-city-page-tsx": () => import("./../../../src/components/pages/CityPage.tsx" /* webpackChunkName: "component---src-components-pages-city-page-tsx" */),
  "component---src-components-pages-company-page-tsx": () => import("./../../../src/components/pages/CompanyPage.tsx" /* webpackChunkName: "component---src-components-pages-company-page-tsx" */),
  "component---src-components-pages-county-page-tsx": () => import("./../../../src/components/pages/CountyPage.tsx" /* webpackChunkName: "component---src-components-pages-county-page-tsx" */),
  "component---src-components-pages-district-page-tsx": () => import("./../../../src/components/pages/DistrictPage.tsx" /* webpackChunkName: "component---src-components-pages-district-page-tsx" */),
  "component---src-components-pages-office-page-tsx": () => import("./../../../src/components/pages/OfficePage.tsx" /* webpackChunkName: "component---src-components-pages-office-page-tsx" */),
  "component---src-components-pages-product-page-tsx": () => import("./../../../src/components/pages/ProductPage.tsx" /* webpackChunkName: "component---src-components-pages-product-page-tsx" */),
  "component---src-components-pages-vendor-list-page-tsx": () => import("./../../../src/components/pages/VendorListPage.tsx" /* webpackChunkName: "component---src-components-pages-vendor-list-page-tsx" */)
}

